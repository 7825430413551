/**
 * 文書カテゴリ 詳細・編集画面定義ファイル
 * 概要
 * 画面単位でのフロント定義について記載する
 * 更新履歴
 * 2022/03/26 追加 尾坂
 */

/** 自動生成で作成したフィールドについて、自動生成だけでは賄えない設定（主にデータベース参照型）を記載する **/
const displayFormSetting = [
  {
    display_id: 'category_id',
    props: {
      isReadonly: true,
      isDisabled: true
    }
  }
];
/** ステータスが存在しないテーブルを元とする画面の許可アクション **/
const allowActionPrefix = '';
/** 詳細の編集完了後ダイアログ定義 **/
const editFinishText = 'xxx情報を編集しました';
const editFinishAction = [
  {
    id: 1,
    color: 'primary',
    type: 'reload',
    name: 'reload',
    value: '閉じる'
  }
];
const deleteFinishAction = [
  {
    id: 1,
    color: 'primary',
    type: 'routing',
    route: '/doc_category_list',
    name: 'delete',
    value: '閉じる'
  }
];
// 自動採番APIの格納フィールドIDと採番フィールドID
const AutoNumberDefine = {
  appNumber: {
    displayId: 'sample_application_no',
    autoFieldId: '_autonum_sample_application_no',
    linkDsFieldId: 'sample_application_no'
  },
  sampleNumber: {
    displayId: 'sample_no',
    autoFieldId: '_autonum_sample_no',
    linkDsFieldId: 'sample_no'
  }
};

// keyの項目を選択した際に、targetの項目を絞り込むための定義
// 例：顧客を選択して、顧客部署が絞り込まれる等
const filterSelect: {
  key: string;
  target: string;
}[] = [
  // { key: 'supplier_id', target: 'supplier_staff_id' },
  // { key: 'payment_destination_supplier_id', target: 'payment_information_id' }
];

export default {
  displayFormSetting,
  allowActionPrefix,
  editFinishText,
  editFinishAction,
  deleteFinishAction,
  AutoNumberDefine,
  filterSelect
};
