/*
 * ドメイン単位で使用するconst・interface・typesについて記載
 */

/** 文書テーブル各種定義 **/

// 検索画面コンポーネントID
export const documentListDisplayId = 'DocumentList';

export const documentListPath = 'doc_list';
export const documentEditPath = 'doc_edit';

// 対象データベース名(ja)
export const documentDBName = 'BT6_文書';

// 初期表示アクション（登録画面用）
export const documentDBRegisterInitActionDisplayId = 'initial_display';
// 初期表示アクション（詳細画面用）
export const documentDBEditInitActionDisplayId = 'update_item';
// 登録アクション
export const documentDBNewActionDisplayId = 'add_new_item';
// 削除アクション
export const documentDBDeleteActionDisplayId = 'delete';

// ステータス画面項目ID
export const documentDBStatusDisplayId = 'status';
// 登録画面 初期ステータス
export const documentDBInitStatusId = '有効';
// 検索条件にステータスがある場合、本定義のステータスは検索条件から除外される
export const documentDBDeleteStatusName = '無効';

export const documentDBFailureStatusMessage = 'ステータス取得失敗';
export const documentDBNotSearchStatusId = [
  // 必要に応じて本ドメインで利用しないステータス項目を定義する
  // 1つのテーブルで複数ドメインを管理する以外では基本記載しない
  'disable'
];

/** 文書カテゴリテーブル各種定義 **/

// 検索画面コンポーネントID
export const documentCategoryListDisplayId = 'DocumentCategoryList';

export const documentCategoryListPath = 'doc_category_list';
export const documentCategoryEditPath = 'doc_category_edit';

// 対象データベース名(ja)
export const documentCategoryDBName = 'BT6_文書カテゴリ';

// 初期表示アクション（登録画面用）
export const documentCategoryDBRegisterInitActionDisplayId = 'initial_display';
// 初期表示アクション（詳細画面用）
export const documentCategoryDBEditInitActionDisplayId = 'update_item';
// 登録アクション
export const documentCategoryDBNewActionDisplayId = 'add_new_item';
// 削除アクション
export const documentCategoryDBDeleteActionDisplayId = 'delete';

// ステータス画面項目ID
export const documentCategoryDBStatusDisplayId = 'Status';
// 登録画面 初期ステータス
export const documentCategoryDBInitStatusId = '有効';
// 検索条件にステータスがある場合、本定義のステータスは検索条件から除外される
export const documentCategoryDBDeleteStatusName = '無効';

export const documentCategoryDBFailureStatusMessage = 'ステータス取得失敗';
export const documentCategoryDBNotSearchStatusId = [
  // 必要に応じて本ドメインで利用しないステータス項目を定義する
  // 1つのテーブルで複数ドメインを管理する以外では基本記載しない
  'disable'
];