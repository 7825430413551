/**
 *  ・クラス名
 *    文書カテゴリマスタ取得モデル
 *  ・概要
 *    文書カテゴリマスタ取得 関連処理を記述
 *  ・更新履歴
 *    2022/03/18 追加 尾坂
 */
import hexabaseState from '@/store/hexabase';
import BaseModel from '../../../models/baseModel';
import { DateTime } from 'luxon';
import {
  documentCategoryListDisplayId,
  documentCategoryDBEditInitActionDisplayId
} from '@/domains/documentManagement/constants/constants';
import {
  StatusActions,
  ItemActions,
  Items,
  FieldsInfo,
  FieldChildrenProp,
  MasterData,
  GetInputFieldsActions,
  GetItemDetailsResponse,
  RegistData
} from '@/services/hexabase/types';
import localDisplayConfig from '@/domains/documentManagement/constants/documentCategoryEdit';
import { DefaultErrorCode } from '@/constants/error';
import router from '@/router';

interface RelatedItems {
  item: { [k: string]: any };
  operation: number;
  action_id?: string;
}
interface Rules {
  id: string;
  rule: string[];
  referenceField?: {
    id: string;
    rule: string;
  }[];
}
interface UpdateData {
  item?: { [k: string]: string };
  rev_no?: number;
  return_item_result?: boolean;
  related_ds_items?: { [k: string]: Related[] };
  comment?: string;
}
interface Related {
  operation?: number;
  action_id?: string;
  rev_no?: number;
  item?: { [k: string]: string };
}

/* eslint-disable @typescript-eslint/camelcase */
export interface Payload {
  conditions?: {
    id: string;
    search_value: Array<string>;
  }[];
  sort_fields?: [
    {
      id: string;
      order: 'desc' | 'asc';
    }
  ];
  per_page?: number;
  page?: number;
  use_display_id?: boolean;
}

export default class DocumentCategoryModel extends BaseModel {
  // 各種設定値をリテラル宣言（ ※コピー後、必要に応じてリテラル値を変更 ）
  readonly listDisplayId = documentCategoryListDisplayId; // 削除処理後に戻る検索画面ID
  // ===============================================================

  // マスターDB名
  private dbName = 't_bt6_document_category';

  payload?: Payload;

  private masterData: { [k: string]: { [k: string]: Array<{ [k: string]: string }> } } = {};

  constructor(payload?: Payload) {
    super(payload);
    const defaultPayload = {
      conditions: [],
      per_page: 0,
      page: 1,
      use_display_id: true
    };
    this.payload = payload ? payload : defaultPayload;
  }

  async init() {
    // 検索条件payloadを使用して、APIから値を取得、各モデル値にセットする
    return await this.getItemList(hexabaseState.applicationId, this.dbName, this.payload);
  }

  async getData() {
    return await this.init();
  }

  public getField(appId: string, dId: string) {
    return this.getFieldsData(appId, dId).then(data => data);
  }

  /**
   * 基本情報入力フォームを生成するための設定情報を取得・整形します
   * @param projectId - 案件ID
   * @param mainDsId - 自動生成元となるテーブルのd_id
   * @param initialDisplayActionDisplayId - 初期表示アクションID
   * @param allowActionDisplayIds - 表示を許可する新規作成アクション群
   * @return { availableNewAction, inputAction, basicFieldCols, basicFieldData } - 自動生成用のColsとDataを返却
   */
  public async getBasicFieldsRegister(
    projectId: string,
    mainDsId: string,
    initialDisplayActionDisplayId: string,
    allowActionDisplayIds: Array<string>
  ) {
    // 対象テーブルに存在する利用可能な新規登録アクションを取得する
    const newAction = await this.getNewActionList(mainDsId);
    // 画面下部に表示する利用可能な新規登録アクションを取得する
    const availableNewAction = newAction.actions.filter(v =>
      allowActionDisplayIds.includes(v.display_id as string)
    );
    // 初期表示に用いるアクションを特定する（テーブルにデータがない状態でも取得する必要があるため、新規登録アクションで定義している）
    const initialDisplayAction = newAction.actions.find(
      v => v.display_id === initialDisplayActionDisplayId
    );
    // 初期表示に用いるアクションの設定を参照し、画面に表示するフィールド項目を生成する
    const inputFields = await this.getFieldsByActionId(mainDsId, initialDisplayAction!.action_id);
    const data = await this.makeFieldsList(projectId, mainDsId).then(data => {
      return this.addOptionalFormField(
        data.filter(v => v.dataType !== 'status'),
        this.deepCopy(localDisplayConfig.displayFormSetting),
        true
      )
        .map(field => this.inputRestriction(field, inputFields))
        .map(field => this.addOptionalMasterData(field, this.masterData));
    });
    const basicFieldCols = this.getGridStatus(data);
    const basicFieldsData = data;
    return { availableNewAction, inputFields, basicFieldCols, basicFieldsData };
  }

  /**
   * 基本情報入力フォームを生成するための設定情報を取得・整形します
   * @param projectId - 案件ID
   * @param mainDsId - 自動生成元となるテーブルのd_id
   * @param itemActions - 初期表示アクションを含むアクション群
   * @param currentData - 登録済みのデータ
   * @return { basicFieldCols, basicFieldData } - 自動生成用のColsとDataを返却
   */
  public async getBasicFieldsEdit(
    projectId: string,
    mainDsId: string,
    itemId: string,
    itemActions: ItemActions[],
    currentData?: FieldsInfo[]
  ) {
    const initialActionSetting = this.getFormDisplayAction(
      itemActions,
      documentCategoryDBEditInitActionDisplayId
    );
    if (initialActionSetting === undefined) return;
    const [inputFields, fieldsList] = await Promise.all([
      this.getFieldsByActionId(mainDsId, initialActionSetting.action_id),
      this.makeFieldsList(projectId, mainDsId, currentData)
    ]);
    let data = this.addOptionalFormField(
      fieldsList.filter(v => v.dataType !== 'status'),
      this.deepCopy(localDisplayConfig.displayFormSetting)
    )
      .map(field => this.inputRestriction(field, inputFields))
      .map(field => this.addOptionalMasterData(field, this.masterData))
      .map(field => this.setSelectAreaItemId(field))
      .map(field => {
        if (field.dataType === 'dslookup') {
          field.props!.isBtnVisible = false;
        }
        if (field.dataType === 'file') {
          field.props!.appId = projectId;
          field.props!.dsId = mainDsId;
          field.props!.itemId = itemId;
        }
        field.props!.isReadonly = true;
        field.props!.isDisabled = true;
        return field;
      });

    // 仕入担当 支払情報絞込み
    data = this.narrowDownSelectOptin(data, localDisplayConfig.filterSelect, this.masterData);

    const basicFieldCols = this.getGridStatus(data);
    const basicFieldsData = data;
    // 備考ボタンのカスタマイズはモデルファイルでは行わない
    // const basicFieldsData = this.changeNoteButtonLabel(
    //   data,
    //   'note',
    //   this.defaultNoteText,
    //   this.existenceNoteText
    // );
    return { basicFieldCols, basicFieldsData };
  }

  /**
   * アイテム更新時の更新処理を行います
   * @param registData - 登録用デーや配列
   * @param workSpaceId - ワークスペースID
   * @param mainDatastoreId - 見出しデータストアID
   * @param linkDatastoreId - 明細データストアID
   * @param itemId - 更新対象アイテムID
   * @param dialogButtons - 表示させるボタン設定配列
   */
  public async itemUpdate(
    registData: RegistData,
    workSpaceId: string,
    mainDatastoreId: string,
    linkDatastoreId: string,
    itemId: string,
    dialogButtons: Array<{ [k: string]: string | number }>
  ) {
    let dialogText = '';
    try {
      registData['is_force_update'] = true;
      const result = await this.updateLinkedItems(
        workSpaceId,
        mainDatastoreId,
        itemId,
        registData,
        linkDatastoreId
      );

      if (result === undefined) throw 'update error';
      dialogButtons.map(v => {
        if (v.name === 'toEdit') {
          v.route = String(v.route).replace(':id', result.item!.i_id);
        }
        return v;
      });
      return { button: dialogButtons, text: localDisplayConfig.editFinishText };
    } catch (e) {
      console.log(e);
      dialogText = 'エラーにより処理できませんでした';
      dialogButtons.splice(0);
      dialogButtons.push({ id: 1, type: 'cancel', name: 'cancel', value: '閉じる' });
      return { error: dialogText, button: dialogButtons };
    }
  }

  /**
   * ステータスアクションを実行します
   * @param registData - 登録用データや配列
   * @param workSpaceId - ワークスペースID
   * @param mainDatastoreId - 見出しデータストアID
   * @param itemId - 更新対象アイテムID
   * @param headAction - 実行可能な見出しアクションリスト
   * @param revNumber - アイテムのリビジョン番号
   * @param buttonType - アクション名（display_id）
   * @param user - ユーザー情報（申請者等の登録時に使用）
   */
  public async execStatusAction(
    registData: RegistData,
    workSpaceId: string,
    mainDatastoreId: string,
    itemId: string,
    headAction: Array<StatusActions | ItemActions>,
    revNumber: number,
    buttonType: string,
    user?: { [k: string]: string }
  ) {
    let dialogText = '';
    try {
      const findAction = headAction.find(action => action.display_id === buttonType);
      if (findAction === undefined || findAction.display_id === undefined)
        throw 'アクションが見つかりません';
      registData = {
        ...registData,
        ...{
          comment: `「${findAction.action_name}」アクションを実行`,
          return_item_result: true,
          rev_no: revNumber
        }
      };
      // ダイヤログ内文言変更
      dialogText = `「${findAction.action_name}」を実行しました`;

      // 削除アクション判断
      if (findAction.crud_type === '3') {
        // 削除を実行
        const result = await this.deleteLinkedItems(workSpaceId, mainDatastoreId, itemId, []);
        this.apiErrorHandle('EXECUTEACTION', result);

        // 削除ボタンの場合は一覧に遷移させる
        const routing = router.options.routes?.find(v => v.name === this.listDisplayId);
        return {
          text: dialogText,
          button: {
            id: 1,
            type: 'routing',
            name: 'route',
            route: routing?.path,
            value: '検索一覧へ'
          }
        };
      } else {
        // ステータスアクションを実行
        const userIid = user === undefined ? '' : user.i_id;
        registData = {
          ...registData,
          ...this.updateActionFields(buttonType, registData, userIid)
        } as RegistData;
        const result = await this.changeStatus(
          workSpaceId,
          mainDatastoreId,
          itemId,
          findAction.display_id,
          registData
        );
        this.apiErrorHandle('EXECUTEACTION', result);
        return { text: dialogText };
      }
    } catch (error) {
      const message =
        typeof error === 'string' ? error : this.getErrMessage('DEFAULT', DefaultErrorCode);
      return {
        error: true,
        text: message,
        button: { id: 1, type: 'reload', name: 'cancel', value: '閉じる' }
      };
    }
  }

  /**
   * 押下したアクションボタン毎に
   * 保存が必要なフィールドに値を設定いたします
   * @param buttonType - アクション名（display_id）
   * @param registData - 登録用デーや配列
   * @param userMasterIid - 社員マスタのユーザーid（アイテムID）
   */
  private updateActionFields(
    buttonType: string,
    registData: RegistData | UpdateData,
    userMasterIid: string
  ) {
    /* eslint-disable @typescript-eslint/camelcase */
    switch (buttonType) {
      case 'order_preparing_edit': {
        // 作成中 - 修正
        if (registData.item === undefined) registData.item = {} as { [k: string]: string };
        registData.item.order_application_edit_employee_id = userMasterIid;
        registData.item.order_application_edit_date = DateTime.local().toString();
        break;
      }

      case 'order_applied_approve': {
        // 発注申請済 - 現場承認
        if (registData.item === undefined) registData.item = {} as { [k: string]: string };
        registData.item.on_site_approval_employee_id = userMasterIid;
        registData.item.on_site_approval_date = DateTime.local().toString();
        break;
      }
      case 'order_applied_edit': {
        // 発注申請済 - 修正する
        if (registData.item === undefined) registData.item = {} as { [k: string]: string };
        registData.item.order_application_edit_employee_id = userMasterIid;
        registData.item.order_application_edit_date = DateTime.local().toString();
        break;
      }
      case 'order_applied_push_back': {
        // 発注申請済 - 差し戻し
        if (registData.item === undefined) registData.item = {} as { [k: string]: string };
        registData.item.order_application_push_back_employee_id = userMasterIid;
        registData.item.order_application_push_back_date = DateTime.local().toString();
        break;
      }

      case 'order_approved_decide': {
        // 現場承認済み - 発注決裁
        if (registData.item === undefined) registData.item = {} as { [k: string]: string };
        registData.item.order_decision_employee_id = userMasterIid;
        registData.item.order_decision_date = DateTime.local().toString();
        break;
      }
      case 'order_approved_edit': {
        // 現場承認済み - 修正
        if (registData.item === undefined) registData.item = {} as { [k: string]: string };
        registData.item.on_site_approval_edit_employee_id = userMasterIid;
        registData.item.on_site_approval_edit_date = DateTime.local().toString();
        break;
      }
      case 'order_approved_push_back': {
        // 現場承認済み - 差し戻し
        if (registData.item === undefined) registData.item = {} as { [k: string]: string };
        registData.item.on_site_approval_push_back_employee_id = userMasterIid;
        registData.item.on_site_approval_push_back_date = DateTime.local().toString();
        break;
      }

      case 'order_decided_send': {
        // 発注決裁済 - 発注処理
        if (registData.item === undefined) registData.item = {} as { [k: string]: string };
        registData.item.order_processing_employee_id = userMasterIid;
        registData.item.order_processing_date = DateTime.local().toString();
        break;
      }

      default: {
        break;
      }
    }
    return registData;
  }
  /**
   * ステータスボタンリストの加工 （wrapper）
   * 各ボタンの活性・非活性等を定義ファイルと付け合わせる
   * @param {string} userId - 社員マスタ内のログインユーザーのアイテムID
   * @param {Array<StatusActions>} statusActionList - ステータスアクションリスト
   * @param {string} part - ドメイン名
   * @param {Array<FieldsInfo>} itemData - アイテム詳細データ
   * @param {string} buttonType - 押下されたボタンアクション
   * @returns {Array} ステータスリスト
   */
  public statusButtonList(
    userId: string,
    statusActionList: Array<StatusActions>,
    itemData?: Array<FieldsInfo>,
    buttonType?: string
  ): Array<StatusActions> {
    // 押下ボタンのタイプがある場合押下アクション以外は非活性
    if (statusActionList.length > 0 && this.isNullOrUndefined(buttonType)) {
      return statusActionList.map(action => {
        action.isDisable = action.display_id !== buttonType || false;
        return action;
      });
    }
    return statusActionList;
  }

  isNullOrUndefined(prop: any) {
    return prop !== undefined && prop !== null;
  }

  /**
   * コンポーネント間のマスターセレクト関連コンポーネントの
   * 値絞込みを行う（マスターセレクト、セレクトエリア、コンボBOX）
   *
   * @param data - フィールド情報
   * @param filterSelect - 絞込みを行う定義
   * @param master - マスタデータ
   * @returns フィールド情報
   */
  public narrowDownSelectOptin(
    data: FieldChildrenProp[],
    filterSelect: { key: string; target: string }[],
    master: MasterData
  ) {
    if (filterSelect.length > 0) {
      for (const index in filterSelect) {
        if (filterSelect[index].key === undefined || filterSelect[index].target === undefined)
          continue;
        const findRow = data.find(field => field.display_id === filterSelect[index].key);
        if (findRow !== undefined) {
          data = this.getNarrowDownSelectOptin(
            data,
            master,
            {
              name: filterSelect[index].key,
              value: findRow.props?.value !== null ? findRow.props?.value.item_id : ''
            },
            filterSelect[index].key,
            filterSelect[index].target
          );
        }
      }
    }
    return data;
  }

  /**
   * 見出しデータの入力必須項目を取得します
   * @param dId - データストアID
   * @param action - 押下されたアクション情報
   * @returns {[k: string]: any} - 入力必須情報
   */
  public async getMainRequired(dId: string, action: { [k: string]: string | number }) {
    const inputFields = await this.getFieldsByActionId(dId, action.action_id as string);
    const orderInput: { [k: string]: any } = this.findRequired(inputFields);
    return orderInput;
  }

  /**
   * 明細データの入力必須項目を取得します
   * @param projectId - データストアID
   * @param dId - データストアID
   * @param items - アイテム情報
   * @param actionName - 押下されたアクションdisplay_id
   * @returns {[k: string]: any} - 入力必須情報
   */
  public async getLinkRequired(projectId: string, dId: string, items: Items[], actionName: string) {
    let detailData: any = {};
    for (const index in items) {
      const item = items[index];
      if (item.item_links !== undefined && item.item_links.links.length > 0) {
        const linkItem = item.item_links.links.find(link => link.d_id === dId);
        if (linkItem) {
          detailData = (await this.getLinkedItems(
            projectId,
            dId,
            linkItem.items[0].i_id
          )) as GetItemDetailsResponse;
          if (detailData.status_actions !== null) break;
        }
      }
    }

    let orderDetailInput: { [k: string]: any } = {};
    if (Object.keys(detailData).length > 0) {
      const detailAction = (detailData.status_actions as Array<StatusActions>).find(
        action => action.display_id === actionName
      );
      if (detailAction !== undefined) {
        const detailInputFields = await this.getFieldsByActionId(dId, detailAction!.action_id);
        orderDetailInput = this.findRequired(detailInputFields);
      }
    }

    return orderDetailInput;
  }

  /**
   * APIから取得した入力必須データをリストに追加します
   * @param fields - GetInputFieldsActions
   * @returns {[k: string]: any} - 必須項目情報
   */
  private findRequired(fields: GetInputFieldsActions) {
    const result: { [k: string]: any } = {};
    const fieldsSetting = fields.action_fields;
    for (const property in fieldsSetting) {
      if (fields.action_field_settings[property].mandatory)
        result[fieldsSetting[property].display_id] = fields.action_field_settings[property];
    }
    return result;
  }

  /**
   * 一括処理実行
   * @param items - アイテム情報
   * @param projectId - アプリケーションID
   * @param mainDId - 見出しデータストアID
   * @param orderInput - 見出しデータ必須項目
   * @param action - 押下されたアクション情報
   * @returns 更新を失敗したアイテム情報
   */
  public async multipleProcesses(
    items: Items[],
    projectId: string,
    mainDId: string,
    orderInput: { [k: string]: any },
    action: { [k: string]: string | number },
    userId: string
  ) {
    const errors: Items[] = [];
    const prefix = 'SAMPLE_';
    for (const i in items) {
      let hasError = false;
      let updateData: UpdateData = {};

      // const data = items[i] as any;

      // const isApplicationNo =
      //   this.isExsist(data[AutoNumberDefine.appNumber.displayId]) &&
      //   data[AutoNumberDefine.appNumber.displayId].length > 0 ? true : false;
      // const isOrderNo =
      //   this.isExsist(data[AutoNumberDefine.orderNumber.displayId]) &&
      //   data[AutoNumberDefine.orderNumber.displayId].length > 0 ? true : false;

      // 見出しデータの必須項目確認
      // 入力がないものはkeyに入ってこない為、keyの存在確認
      const itemIds = Object.keys(items[i]);
      const targetIds = Object.keys(orderInput);
      for (const displayId in targetIds) {
        if (!itemIds.includes(targetIds[displayId])) {
          const errMessage = this.getErrMessage('BATCH_PROCESSING', `${prefix}MAIN_MANDATORY`);
          errors.push({ ...items[i], ...{ error_message: errMessage } });
          hasError = true;
          break;
        }
      }
      if (hasError) continue;

      // データベース参照型の場合nullがあり得るため確認
      for (const [key, value] of Object.entries(items[i])) {
        if (
          orderInput[key] !== undefined &&
          orderInput[key].mandatory &&
          (value === '' || value === null)
        ) {
          const errMessage = this.getErrMessage('BATCH_PROCESSING', `${prefix}MAIN_MANDATORY`);
          errors.push({ ...items[i], ...{ error_message: errMessage } });
          hasError = true;
          break;
        }
      }
      if (hasError) continue;

      // // 関連データが存在するか確認
      // const linkItem = await this.getLinked(projectId, mainDId, items[i].i_id, relatedDId);
      // if (!isDelete && (linkItem.has_error || linkItem.result['t_sample_detail'].items.length === 0)){
      //   const errMessage = this.getErrMessage('BATCH_PROCESSING', `${prefix}NOT_EXIST`);
      //   errors.push({...items[i], ...{error_message: errMessage}});
      //   continue;
      // }

      // // 関連データの必須項目確認
      // for (const index in linkItem.result['t_sample_detail'].items){
      //   const item = linkItem.result['t_sample_detail'].items[index];

      //   // データの必須項目確認
      //   // 入力がないものはkeyに入ってこない為、keyの存在確認
      //   const itemIds = Object.keys(item);
      //   const targetIds = Object.keys(orderDetailInput);
      //   for (const displayId in targetIds){
      //     if (!itemIds.includes(targetIds[displayId])){
      //       const errMessage = this.getErrMessage('BATCH_PROCESSING', `${prefix}DETAIL_MANDATORY`);
      //       errors.push({...items[i], ...{error_message: errMessage}});
      //       hasError = true;
      //       break;
      //     }
      //   }
      //   if(hasError) break;

      //   // データベース参照型の場合nullがあり得るため確認
      //   for (const [key, value] of Object.entries(item)){
      //     if(orderDetailInput[key] !== undefined && orderDetailInput[key].mandatory && (value === '' || value === null)){
      //       const errMessage = this.getErrMessage('BATCH_PROCESSING', `${prefix}DETAIL_MANDATORY`);
      //       errors.push({...items[i], ...{error_message: errMessage}});
      //       hasError = true;
      //       break;
      //     }
      //   }
      //   if (hasError) break;
      // }
      // if(hasError) continue;

      // const updateLinkedData: {[k: string]: any}[] = [];
      // for(const relatedIndex in linkItem.result.t_sample_detail.items){
      //   const detail = await this.getLinkedItems(
      //     projectId,
      //     relatedDId,
      //     linkItem.result.t_sample_detail.items[relatedIndex].i_id,
      //   );
      // }

      updateData.rev_no = Number(items[i].rev_no);
      updateData.return_item_result = true;
      updateData = this.updateActionFields(action.display_id as string, updateData, userId);

      // // 発注申請の場合は申請番号追加
      // if (action.display_id === ApplicationNumberIssuanceActionId && !isApplicationNo) {
      //   const autoNumber = await this.getAutoNumber(projectId, mainDId, AutoNumberDefine.appNumber.autoFieldId, {});;
      //   const id = AutoNumberDefine.appNumber.displayId;
      //   if (updateData.item === undefined) updateData.item = {} as {[k:string]: string};
      //   updateData.item[id] = autoNumber.result.value;
      //   updateLinkedData = updateLinkedData.map(item => {
      //     item.item = {};
      //     item.item[id] = autoNumber.result.value;
      //     return item;
      //   });
      // }

      // // 発注決裁の場合は発注番号追加
      // if (action.display_id === OrderNumberIssuanceActionId && !isOrderNo) {
      //   const autoNumber = await this.getAutoNumber(projectId, mainDId, AutoNumberDefine.orderNumber.autoFieldId, {});
      //   const id = AutoNumberDefine.orderNumber.displayId;
      //   if (updateData.item === undefined) updateData.item = {} as {[k:string]: string};
      //   updateData.item[id] = autoNumber.result.value;
      //   updateLinkedData = updateLinkedData.map(item => {
      //     item.item = {};
      //     item.item[id] = autoNumber.result.value;
      //     return item;
      //   });
      // }

      if (updateData.related_ds_items === undefined) {
        updateData.related_ds_items = {};
      }
      // updateData.related_ds_items[relatedDId] = [...updateLinkedData];

      const actionId = action.display_id as string;
      if (action.crud_type === '3') {
        try {
          const result = await this.deleteLinkedItems(projectId, mainDId, items[i].i_id, []);
          // エラーになった場合はエラー種別を特定しメッセージを取得
          const errMessage = this.getErrMessage('EXECUTEACTION', result);
          if (errMessage.length > 0) errors.push({ ...items[i], ...{ error_message: errMessage } });
        } catch (err) {
          errors.push({
            ...items[i],
            ...{ error_message: this.getErrMessage('DEFAULT', DefaultErrorCode) }
          });
        }
      } else {
        try {
          const result = await this.changeStatus(
            projectId,
            mainDId,
            items[i].i_id,
            action.action_id as string,
            updateData
          );
          // エラーになった場合はエラー種別を特定しメッセージを取得
          if (this.isExsist(result)) {
            const errMessage = this.getErrMessage('EXECUTEACTION', result);
            if (errMessage.length > 0)
              errors.push({ ...items[i], ...{ error_message: errMessage } });
          }
        } catch (err) {
          errors.push({
            ...items[i],
            ...{ error_message: this.getErrMessage('DEFAULT', DefaultErrorCode) }
          });
        }
      }
    }

    return errors;
  }

  public async getCurrentItem(aId: string, dId: string, iId: string, excludeList: string[]) {
    const orderBasicResult = (await this.getLinkedItems(aId, dId, iId)) as GetItemDetailsResponse;
    // フロント側で定義した除外するステータスが含まれていないか確認する
    // 基本使用する必要はないが、同一テーブルで複数ドメインを管理する場合に利用する
    this.checkInitialItem(orderBasicResult, excludeList);
    return orderBasicResult;
  }

  /**
   * マスタデータをフロント（マスタコントロール）で
   * 使用可能な形に整形して返します
   */
  async getFormatData() {
    return await this.getData().then(data =>
      this.modifyMasterData(data, 'category_id', this.dbName)
    );
  }
}
